/*========================================================*/
/*** LOGIN ***/
/*========================================================*/
#account-login {
    @media (max-width: $tablet) {
        width: 100%;
    }
    .breadcrumb {
        display: none;
    }
    #content {
        padding-top: clamp(50px, 5.2083vw, 100px);
    }
    .well {
        // bootstrap reset
        padding: 20px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        &.new-customer-form:before {
            content: '';
            width: 1px;
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            @media (max-width: $tablet-sm) {
                content: none;
            }
        }
        @media (max-width: $tablet-sm) {
            padding: 10px;
        }
    }
    .register-btn {
        max-width: 200px;
        margin: 25px auto 0;
    }
    .forgotten-pass {
        margin-top: 5px;
    }
}
/*========================================================*/
/*** SOCIAL LOGIN ***/
/*========================================================*/
#d_social_login {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto clamp(30px, 2.6042vw, 50px) auto;
    .d-social-login__button {
        display: block;
        width: 100%;
        color: $white;
        text-decoration: none;
        .d-social-login__button__icon {
            max-width: 100%;
            text-align: left;
            width: 100%;
            display: block;
            position: relative;
            padding-left: 45px;
            img {
                max-width: 30px;
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        &.d-social-login__button--facebook .d-social-login__button__icon img {
            max-width: 10px;
        }
        &.d-social-login__button--google span.d-social-login__button__icon img {
            max-width: 25px;
        }
    }
}

/*========================================================*/
/*** REST ACCOUNT PAGES ***/
/*========================================================*/
.common-account {
    padding: clamp(50px, 5.2083vw, 100px) 15px;
    float: none;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    &.large-container {
        max-width: 900px;
    }
    .breadcrumb {
        display: none;
    }
    legend {
        font-size: $text_fs;
        font-weight: 500;
        border-bottom: none;
        margin: 0 0 15px;
        text-transform: uppercase;
    }
    .terms {
        margin: 0 0 15px;
    }
    ul > li > a {
        line-height: 2.5;
        text-decoration: none;
    }
    .btn-default {
        text-decoration: none !important;
    }
    .col-sm-2.control-label {
        display: none;
    }
    .default-address,
    .is-opened {
        display: block !important;
        text-align: left;
        width: 100%;
    }
    .buttons {
        margin-top: 15px;
    }
    .col-sm-10 {
        display: block;
        width: 100%;
    }
    .table-bordered {
        > tbody > tr > td,
        > tbody > tr > th,
        > tfoot > tr > td,
        > tfoot > tr > th,
        > thead > tr > td,
        > thead > tr > th {
            border: none;
            border-bottom: 1px solid $border_color;
            padding: 20px 15px;
        }
    }

    td.text-left a {
        color: #000;
        text-decoration: none;
    }
    td.text-right > a {
        text-decoration: none;
    }
    .add-to-cart-icon svg,
    .add-to-cart-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
    .remove-item-icon svg .st0 {
        fill: none;
        stroke: #000000;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-miterlimit: 10;
    }
    .remove-item-icon svg,
    .remove-item-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
    }
    thead > tr > td {
        font-size: 1.4rem;
        letter-spacing: 0.025em;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }
    tbody > tr > td {
        font-size: 1.4rem;
        font-weight: 400;
        color: #000;
    }
    .table-hover > tbody > tr {
        opacity: 0.7;
        background-color: transparent;
    }
    .table-hover > tbody > tr:hover {
        opacity: 1;
    }
    td.text-right > a.edit-address-btn,
    td.text-right > a.remove-address-btn,
    td.text-right > a.btn-view-item {
        font-size: 1.1rem;
        line-height: initial;
        font-weight: 600;
        letter-spacing: 0.025em;
        color: #000;
        border: none;
        border-radius: 0;
        background-image: none;
        text-transform: uppercase;
        text-decoration: underline;
    }
    &.account-voucher {
        .col-sm-2.control-label {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}

// NO IDEA FROM DOWN HERE
.container.common-account.small-container {
    max-width: 500px;
}

.input-group.date button.btn.btn-default {
    padding: 9px 20px;
}
label.col-sm-3.control-label.newsletter-text,
label.col-sm-4.control-label.newsletter-text {
    text-align: left;
}
.form-group {
    .custom-field{
        display: none;
    }
}