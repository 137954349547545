/*========================================================*/
/* Homepage style_m */
/*========================================================*/

/*home categories*/
.home-categories-container {
    margin: 75px auto;
}
.categories-wrapper.flex-row {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    justify-content: center;
}
.categories-wrapper .category-item {
    position: relative;
}
.categories-wrapper .category-item a {
    color: #000;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    padding-bottom: 70px;
}
.categories-wrapper .category-item:first-child {
    margin-right: 15px;
}
.categories-wrapper .category-item:last-child {
    margin-left: 15px;
}
.categories-wrapper .category-item .category-info {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 30px 25px 25px;
    background-color: rgba(255, 255, 255, 1);
    width: calc(100% - 8.333vw);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.categories-wrapper .category-item a:hover .category-info {
    bottom: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.categories-wrapper .category-item .title {
    justify-content: space-between;
    align-items: center;
}
.categories-wrapper .category-item .title h3 {
    max-width: calc(100% - 120px);
}
.categories-wrapper .category-item .title .category-btn {
    font-size: 1.4rem;
    font-weight: 600;
}
.categories-wrapper .category-item .title .category-btn:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background-color: #000;
    position: relative;
    bottom: -3px;
}
/*home carousel tabs*/
.carousel-container {
    margin: 100px auto;
    padding: 0 30px;
}
.carousel-container .nav-tabs {
    text-align: center;
    border-bottom: none;
    margin-bottom: 30px;
}
.carousel-container .nav-tabs > li {
    float: unset;
    display: inline-block;
}
.carousel-container .nav-tabs > li a {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #000;
    border: none;
    padding: 10px 25px;
    margin: 0;
}
.carousel-container .nav-tabs > li.active a {
    font-size: 2.4rem;
    line-height: 2;
    font-weight: 600;
    color: #000;
    border: none;
}
.carousel-container .nav-tabs > li a:focus,
.carousel-container .nav-tabs > li a:hover {
    background-color: transparent;
}
.carousel-container .nav-tabs > li.active a:after {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background-color: #000;
    position: relative;
    bottom: -3px;
}

/*carousel slick dots*/
.carousel-container .tab-content .tab-pane {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
}
.carousel-container .tab-content .tab-pane.active {
    height: auto;
}
.carousel-container .tab-content .caption {
    margin-top: 15px;
}
.carousel-container .tab-content .tab-pane .carousel-wrapper {
    position: relative;
    padding-bottom: 55px;
}
.carousel-container .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
.carousel-container .slick-dots li {
    display: inline-block;
    margin: 0 10px;
}
.carousel-container .slick-dots li button {
    font-size: 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 0;
    background-color: #c8c8c9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
}
.carousel-container .slick-dots li.slick-active button {
    background-color: #000;
}

/*carousel slick arrows */
.carousel-arrows {
    display: none;
}
.show-slider-nav + .carousel-arrows {
    display: block;
}
.carousel-container .carousel-arrows {
    position: absolute;
    left: 50%;
    bottom: 15px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 190px;
    width: 100%;
}
.carousel-container .carousel-arrows .arrow,
.carousel-container .carousel-arrows .arrow svg {
    display: block;
    width: 30px;
    height: 9px;
    cursor: pointer;
}
.carousel-container .carousel-arrows .arrow {
    position: absolute;
}
.carousel-container .carousel-arrows .arrow.next {
    right: 0;
}

/*home more categories*/
.more-category-wrapper.flex-row {
    justify-content: center;
    padding-top: 50px;
}
.home-more-categories {
    padding: 0 15px;
    padding-bottom: 40px;
}
.home-more-categories h2 {
    margin-bottom: 50px;
}
.home-more-categories .category-item:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.more-category-wrapper .category-item {
    position: relative;
    margin: 0 15px;
}
.more-category-wrapper .category-info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 25px 30px;
    width: 100%;
    z-index: 1;
}
.more-category-btn {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    padding: 15px 25px;
    position: relative;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.category-item a:hover .more-category-btn {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.more-category-wrapper .category-item img {
    align-self: center;
}
.slider-carousel-slick {
    position: relative;
    padding: 0 40px;
    .slider-arrows {
        margin-top: -8px;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        .arrow {
            width: 10px;
            height: 15px;
            position: absolute;
            cursor: pointer;
            &.arrow-prev {
                left: 15px;
            }
            &.arrow-next {
                right: 15px;
            }
        }
    }
}

@media (max-width: $laptop-m) {
    /*homepage*/
    .more-category-wrapper .category-info {
        padding: 20px;
    }
    .more-category-wrapper .category-info h3 {
        font-size: 2.5rem;
    }
    .more-category-btn {
        padding: 10px 20px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 2rem;
    }
}

@media (max-width: $tablet-lg) {
    /*homepage*/
    .home-categories-container {
        margin: 25px auto 75px;
    }
    .categories-wrapper .category-item .category-info {
        width: calc(100% - 3.333vw);
        padding: 20px;
    }
    .more-category-wrapper.flex-row {
        flex-wrap: wrap;
    }
    .more-category-wrapper .category-item {
        margin-bottom: 30px;
        // width: calc(50% - 30px);
    }
    .more-category-wrapper .category-item a {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: $tablet) {
    /*homepage*/
    .home-categories-container {
        margin-bottom: 55px;
    }
    .categories-wrapper .category-item .title h3 {
        font-size: 1.5rem;
        max-width: 100%;
    }
}

@media (max-width: $tablet-sm) {
    /*homepage*/
    .categories-wrapper.flex-row {
        flex-direction: column;
        padding: 0 15px;
    }
    .home-categories-container {
        margin-bottom: 35px;
    }
    .categories-wrapper .category-item:first-child,
    .categories-wrapper .category-item:last-child {
        margin: 0 auto;
    }
    .categories-wrapper .category-item + .category-item {
        margin-top: 15px;
    }
    .categories-wrapper .category-item .category-info {
        width: calc(100% - 15vw);
        padding: 10px;
        text-align: center;
    }
    .categories-wrapper .category-item .title h3 {
        margin: 5px auto;
    }
    .categories-wrapper .category-item .title {
        flex-direction: column;
    }
    .home-more-categories {
        padding-bottom: 0;
    }
    .home-more-categories h2 {
        font-size: 2rem;
    }
    .more-category-wrapper .category-item {
        // width: calc(50% - 30px);
        margin: 0 auto 15px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 1.6rem;
        padding: 15px;
    }
}

@media (max-width: $mobile) {
    /*homepage*/
    .carousel-container {
        margin-top: 20px;
        padding: 0 15px;
    }
    .carousel-container .nav-tabs {
        margin-bottom: 20px;
    }
    .carousel-container .nav-tabs > li a {
        font-size: 1.5rem;
        padding: 10px;
    }
    .carousel-container .nav-tabs > li.active a {
        font-size: 1.6rem;
    }
}

.article-image > a {
    display: block;
    max-height: 210px;
    overflow: hidden;
}

.more-desc {
    background: #fff;
    padding: 30px 20px;
    margin-top: 0px;
    float: left;
    width: 100%;
}

span.date-added {
    font-size: 14px;
    color: #555;
    font-weight: 300;
}

.article-author {
    text-decoration: none;
    font-weight: bold;
}

.article-author a {
    text-decoration: none;
    font-weight: bold;
}

.article-author h4 {
    margin: 0;
    text-transform: uppercase;
    font-weight: 400;
    color: #353291;
    margin-top: 8px;
    height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.home-posts {
    padding-top: 70px;
    padding-bottom: 50px;
    h3.panel-title {
        margin-bottom: 45px;
        font-size: 2.4rem;
        line-height: 2;
        font-weight: 600;
        color: #000000;
    }
    .description {
        height: 55px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    a.ola-ta-blog {
        display: block;
        margin: 40px auto 0;
        border: 1px solid #000000;
        color: #000000;
        padding: 10px;
        text-align: center;
        width: fit-content;
        &:hover {
            background-color: #000000;
            color: #f2f2f2;
        }
    }
}
.article-image {
    @media (max-width: $tablet-sm) {
        img {
            margin: auto;
        }
    }
}
.homepage-blog {
    display: none;
}


h3.panel-title img {
    width: 44px;
    margin-left: 14px;
    position: relative;
    top: -3px;
    display: unset;
}

h3.panel-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 22px;
    color: #444;
}

.homepage {
    &__social {
        margin-top: max(80px, vw(110px));
        margin-bottom: vw(110px);
        margin-left: vw(65px);
        margin-right: vw(65px);
        @media (max-width: $tablet-sm) {
            margin-top: vw(110px);
        }

        &--title {
            text-align: center;
            margin-bottom: max(30px, vw(65px));
            font-family: var(--font-default), sans-serif;
            font-size: 3.4rem;
            font-weight: 100;
            font-style: normal;
            line-height: 1.2;
            @media (max-width: $tablet-sm) {
                font-size: 2.4rem;
            }
        }

        &__item {
            width: vw(350px) !important;
            height: vw(450px) !important;
            margin-right: vw(30px) !important;


            a {
                display: flex;
                width: 100%;
                height: 100%;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }

            .social-view {
                a {
                    img {
                        width: vw(507px);
                        height: vw(507px);
                        object-fit: cover;
                    }
                }
            }

            &:hover {


                &:before {
                    @include transition;
                    opacity: 1;
                }
            }
        }
    }
}
.homepage__social .slider-wrap{
    display: none;
    position:  relative !important;
}

.arrow-right-span{
    font-size: 25px;
    font-weight: bold;
    padding: 3px;
    padding-left:5px
}
.arrow-left-span{
    font-size: 25px;
    font-weight: bold;
    padding: 3px;
}

@media (max-width: $mobile) {

    .arrow-right-span{
        font-size: 13px;
        font-weight: normal;
    }
    .arrow-left-span{
        font-size: 13px;
        font-weight: normal;
    }
}

.summerPopup {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.summerPopup-content {
    background-color: white;
    padding: 20px;
    width: 66%;
    max-width: 600px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.summerPopup_close {
    font-size: 36px;
    color: #000;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    z-index: 1001;
}
@media (max-width: 767px) {
    .summerPopup_close {
        top: 1px;
        right: 5px;
        font-size: 25px;
    }
}
.summerPopup-left,
.summerPopup-right {
    width: 100%;
}
.summerPopup-left img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
.summerPopup-right {
    padding-top: 15px;
}
.summerPopup-right h1 {
    font-size: 28px;
    margin-bottom: 10px;
}
.summerPopup-right h2 {
    font-size: 20px;
    margin: 0;
}
@media (min-width: 768px) {
    .summerPopup-content {
        flex-direction: row;
        width: 60%;
    }
    .summerPopup-right {
        padding-left: 20px;
    }
    .summerPopup-right h1 {
        font-size: 34px;
    }
    .summerPopup-right h2 {
        font-size: 24px;
    }
}