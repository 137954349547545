/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
.category-container {
    padding: 0 35px;
}
.breadcrumb {
    text-align: center;
    margin: 0;
    padding: 30px 0 15px 0;
    border: none;
    background-color: transparent;
    li {
        padding: 0;
        a {
            display: inline-block;
            font-size: 1.2rem;
            line-height: 3.7rem;
            font-weight: 400;
            letter-spacing: 0.025rem;
            color: #000000;
        }
        &::after {
            content: '/';
            display: inline-block;
            margin: 0 7px 0 10px;
        }
        &:last-child{
            &::after {
                display: none;
            }
        }
        &+li{
            &:before {
                display: none;
            }
        }
    }
}

.flex-row.categories-choices {
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 55px;
}
.category-container .category-info {
    padding-bottom: 35px;
}
.products-block .product-thumb .caption {
    padding: 15px;
    min-height: 150px;
}
.products-filters {
    width: 100%;
    max-width: 200px;
    margin-right: 10px;
}
.category-grid {
    width: 100%;
    max-width: 1640px;
    margin-left: 10px;
}
.products-block {
    width: 100%;
    display: inline-block;
    margin: 0 -15px;
}
.product-col {
    width: 25%;
    display: inline-block;
    float: left;
    padding: 0 10px;
    position: relative;
}
span.add-to-wishlist-icon svg .st0 {
    fill: #bcbcbc;
    stroke: #000;
}
.is-favorite {
    .add-to-wishlist-icon {
        svg {
            .st0 {
                fill: #EC1D25;
                stroke: #EC1D25;
            }
        }
    }
}
span.add-to-wishlist-icon svg,
span.add-to-wishlist-icon {
    display: block;
    width: 26px;
    height: 22px;
}
//.product-thumb a.add-to-wishlist {
//    display: none;
//    width: 21px;
//    height: 19px;
//    position: absolute;
//    top: 15px;
//    right: 30px;
//}
//.product-thumb:hover a.add-to-wishlist {
//    display: block;
//}
.product-col:nth-child(4n + 1) {
    clear: both;
}
.products-block .product-thumb {
    margin-bottom: 60px;
    cursor: pointer;
    position: relative;
    overflow: initial;
}
.product-thumb {
    border: none;
}
.category-info h2 {
    text-align: center;
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    margin: 0 0 25px;
    display: none;
}
.category-info .category-description {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.3rem;
    line-height: 2.3rem;
    font-weight: 400;
    letter-spacing: 0.025em;
}
.category-description {
    #hidden {
        display: none;
    }
    #show-more {
        margin: 15px auto 0;
        display: block;
        font-size: 1.5rem;
        cursor: pointer;
        position: relative;
        width: fit-content;
        width: -moz-fit-content;
        &:after {
            content: "";
            width: 0;
            height: 2px;
            background-color: var(--primary-color);
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 0;
            -webkit-transition: width 0.3s ease;
            -moz-transition: width 0.3s ease;
            -o-transition: width 0.3s ease;
            transition: width 0.3s ease;
        }
        &:hover:after {
            width: 100%;
            margin-left: 0;
            -webkit-transition: width 0.3s ease;
            -moz-transition: width 0.3s ease;
            -o-transition: width 0.3s ease;
            transition: width 0.3s ease;
        }
    }
}
.category-info p {
    text-align: justify;
    font-size: 1.3rem;
    line-height: 2.3rem;
    font-weight: 400;
    letter-spacing: 0.025em;
}
span.category-separator {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #eaeaea;
    margin: 25px 0 35px;
}
.total-products p {
    font-size: 1.2rem;
    line-height: 3rem;
    color: #747474;
    font-weight: 400;
    letter-spacing: 0.025em;
}
.category-short-by {
    position: relative;
    width: 100%;
    max-width: 220px;
}
.category-short-by .form-group.input-group {
    width: 100%;
    max-width: 220px;
}
.category-short-by label {
    display: none;
}
.category-short-by select#input-sort {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    font-size: 1.2rem;
    letter-spacing: 0.025em;
    -webkit-appearance: initial;
    height: unset;
    padding: 15px 25px;
}
span.sort-icon svg .st0 {
    fill: #010101;
}
span.sort-icon,
span.sort-icon svg {
    display: block;
    width: 10px;
    height: 13px;
    position: absolute;
    top: 8px;
    left: 0;
    z-index: 9;
}
.caption > .product-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.caption ul.product-available-colors {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.caption ul.product-available-colors > li {
    display: inline-block;
}
.caption ul.product-available-colors > li:not(last-child) {
    margin-right: 5px;
}
.caption ul.product-available-colors > li img {
    border-radius: 50%;
    max-width: 19px;
}
.product-thumb .price-tax {
    display: none;
}
.flex-row.category-pagination {
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}
.pagination > li > a,
.pagination > li > span {
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 400;
    color: #000000;
    background-color: transparent;
    border: none;
    padding: 12px;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    color: #000000;
    background-color: #eaeaea;
    border-color: #eaeaea;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    font-size: 1.4rem;
    line-height: 1.2rem;
    font-weight: 800;
    letter-spacing: 0.025em;
    color: #000;
    background-color: transparent;
    border-color: transparent;
}
.category-pagination span.arrow-left-start {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-left-first.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}
.category-pagination span.arrow-right-end {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-right-last.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}
.category-pagination span.arrow-next {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-next.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}
.category-pagination span.arrow-prev {
    display: block;
    content: '';
    background-image: url('../../../icons/arrow-prev.svg');
    width: 12px;
    height: 12px;
    background-position: center;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.products-filters .list-group,
.products-filters .swiper-viewport,
.box.bf-check-position .box-heading {
    display: none;
}
.products-filters .box {
    border: none;
    background-color: transparent;
    border-radius: 0;
}
.products-filters .bf-form > .bf-attr-block:first-of-type .bf-attr-header,
.bf-attr-block .bf-attr-header.bf-clickable {
    font-size: 1.6rem;
    line-height: 6rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #eaeaea;
    padding: 7px 0;
}
.bf-attr-block .bf-close-btn,
.bf-attr-block .bf-arrow {
    position: absolute;
    top: 40%;
    right: 0;
    -webkit-transform: translateX(-40%);
    -moz-transform: translateX(-40%);
    -o-transform: translateX(-40%);
    transform: translateX(-40%);
}
.bf-cell {
    border-bottom: none !important;
}
.bf-attr-block-cont {
    padding: 0;
}
.bf-attr-filter .bf-cell:first-child {
    display: none;
}
.bf-attr-filter .bf-cell label {
    position: relative;
    display: inline-block;
    font-size: 1.1rem;
    line-height: initial;
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
}
.bf-attr-filter .bf-cell label:after {
    display: inline-block;
    content: '';
    width: 0;
    background-color: #000000;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.bf-attr-filter .bf-cell label:hover:after {
    width: 100%;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
}
.bf-attr-block .bf-attr-block-cont {
    padding: 10px 0;
}
.products-filters .bf-price-slider-container .bf-slider-range .ui-slider-range.ui-widget-header.ui-corner-all {
    background: #000000;
}
.products-filters .bf-buttonclear-box {
    border: 1px solid #000;
    background-color: #000;
    margin-top: 30px;
}
.products-filters .bf-buttonclear {
    background: none !important;
    background-repeat: no-repeat;
    text-decoration: none;
    padding-left: 0;
    line-height: initial;
    color: transparent;
}
.products-filters .bf-buttonclear:hover {
    background: none !important;
}
.products-filters input[type='reset'] {
    text-align: center;
    color: #fff;
    background-color: transparent;
    border-color: #000;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.025em;
}
.products-filters .bf-slider-container.ui-slider-horizontal,
.products-filters .bf-slider-range.ui-slider-horizontal {
    height: 3px;
}
.products-filters .ui-slider-horizontal .ui-slider-handle {
    top: -7px;
}
.products-filters span.ui-slider-handle.ui-state-default.ui-corner-all {
    left: 0;
    background: #fff !important;
    width: auto !important;
    height: 22px !important;
    border: 1px solid #000 !important;
    border-radius: 8px !important;
    text-align: center;
    padding: 0 4px;
    font-size: 1.1rem;
    top: -11px;
}
.products-filters span.ui-slider-handle.ui-state-default.ui-corner-all:last-child {
    -webkit-transform: translate(-69%, 0);
    -moz-transform: translate(-69%, 0);
    -o-transform: translate(-69%, 0);
    transform: translate(-69%, 0);
}
.bf-price-container.box-content.bf-attr-filter > .bf-cur-symb {
    display: none;
}
.products-filters .bf-cur-symb {
    padding-bottom: 20px;
}
.products-filters input[type='text'] {
    border: 1px solid #000000;
}
.product-thumb .price-old {
    color: #000000;
    margin-left: 0;
    font-size: 1.2rem;
    font-weight: 400;
    text-decoration: line-through;
}
.product-thumb .price-new {
    font-weight: 800;
    color: #ec1d25;
    margin-left: 5px;
}
.product-thumb span.discount-percentage {
    font-weight: 400;
    color: #747474;
    margin-left: 5px;
}
.product-thumb .image {
    position: relative;
    padding: 0 10px;
}
.product-thumb .image a.has-thumb {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 0 10px;
}
.product-thumb:hover .image a.has-thumb {
    visibility: visible;
    opacity: 1;
}
.sub-categories {
    width: 100%;
    max-width: 1530px;
    display: inline-block;
    margin: 0 -15px;
}
.sub-categories .sub-category-column {
    width: 20%;
    display: inline-block;
    float: left;
    padding: 0 10px;
    position: relative;
    margin-bottom: 10px;
}
h4.sub-category-name {
    display: inline-block;
    position: relative;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 3.6rem;
}
h4.sub-category-name:after {
    display: inline-block;
    content: '';
    width: 0;
    height: 1px;
    background-color: #000;
    position: absolute;
    bottom: 8px;
    left: 0;
    @media (max-width: $tablet-sm) {
        display: none;
    }
}
.sub-categories .sub-category-item {
    width: 100%;
    max-width: 255px;
    text-align: center;
}
.sub-categories .sub-category-item:hover h4.sub-category-name:after {
    width: 100%;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
}
.sub-categories .sub-category-item:hover img {
    opacity: 0.8;
}
.badge {
    position: absolute;
    top: 0;
    left: 10px;
    width: 57px;
    height: 25px;
    border: 1px solid #ec1d25;
    border-radius: 5%;
    background-color: transparent;
}
span.sale_badge {
    background-color: #ec1d25;
    display: none;
}
.badge i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-style: normal;
    font-size: 1.3rem;
    color: #000;
    letter-spacing: 0.025em;
    margin-top: -1px;
}
span.sale_badge i {
    color: #fff;
}
.bf-cell.bf-cascade-1 {
    background-image: none;
}
@media (max-width: $laptop-m) {
    /*category*/
    .product-col {
        width: 33.333%;
    }
    .product-col:nth-child(3n + 1) {
        clear: both;
    }
    .product-col:nth-child(4n + 1) {
        clear: none;
    }
}

@media (max-width: $laptop-xs) {
    .sub-categories .sub-category-column {
        width: 25%;
    }
}

@media (max-width: $tablet) {
    /*category*/
    .caption > h4,
    h4.sub-category-name {
        line-height: 1.5;
    }
    .product-col {
        width: 50%;
    }
    .product-col:nth-child(3n + 1) {
        clear: none;
    }
    .product-col:nth-child(2n + 1) {
        clear: both;
    }
}

@media (max-width: $tablet-sm) {
    /*category*/
    .breadcrumb {
        padding: 10px 0;
    }
    .category-container {
        padding: 0 15px;
    }
    .products-block {
        margin: 0 auto;
    }
    .caption > h4 {
        font-size: 1.4rem;
    }

    .category-grid {
        margin-left: 0;
    }
    .sub-categories {
        margin: 0 auto;
    }
    .sub-categories .sub-category-column:nth-child(4n + 1) {
        clear: both;
    }
    h4.sub-category-name {
        font-size: 1.2rem;
    }
    span.category-separator {
        margin: 15px 0;
    }
    /*filters*/
    .filters-btn {
        color: #000;
        font-size: 1.2rem;
        letter-spacing: 0.025em;
        width: calc(50% - 15px);
        border-bottom: 1px solid #000;
        padding-bottom: 9px;
        max-height: 35px;
    }
    .filters-btn:hover,
    .filters-btn:active,
    .filters-btn:focus {
        color: #000;
    }
    .filter-icon,
    .filter-icon svg {
        display: inline-block;
        width: 12px;
        height: 13px;
    }
    .filter-icon svg .st0 {
        fill: #000;
    }
    .filters-btn .text {
        display: inline-block;
        padding-left: 5px;
    }
    .close-filters {
        position: fixed;
        top: 0;
        right: 0;
        height: 22px;
        z-index: 99;
        background-color: #fff;
        width: 100%;
    }
    .close-filters-icon,
    .close-filters-icon svg {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 5px;
        float: right;
    }
    .close-filters .filters-icon svg .st0 {
        fill: #000;
    }
    .category-short-by {
        width: calc(50% - 15px);
        max-width: unset;
    }
    .category-short-by .form-group.input-group {
        max-width: unset;
    }
    body.category-filters-open {
        overflow: hidden;
    }
    .products-filters.leftSidebar {
        position: fixed;
        top: 0;
        left: -100%;
        background: rgba(0, 0, 0, 0);
        width: 100%;
        max-width: unset;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
        -webkit-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
        -moz-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
        -o-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
        transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    }
    .products-filters.leftSidebar.active {
        left: 0;
        opacity: 1;
        visibility: visible;
        background: rgba(0, 0, 0, 0.7);
        -webkit-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
        -moz-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
        -o-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
        transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    }
    #column-left {
        max-width: 50%;
        padding: 0 20px;
        height: 100vh;
        background-color: #fff;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .products-filters .bf-panel-wrapper {
        height: calc(100vh - 50px);
        overflow-y: auto;
        padding-top: 10px;
    }
    .products-filters .bf-buttonclear-box {
        position: fixed;
        bottom: 15px;
        left: 15px;
        width: calc(100% - 30px);
        z-index: 9;
    }
    .products-filters .bf-form > .bf-attr-block:first-of-type .bf-attr-header,
    .bf-attr-block .bf-attr-header.bf-clickable {
        font-size: 1.4rem;
        line-height: 2;
    }
    .products-filters .bf-cross,
    .products-filters .bf-close-btn,
    .products-filters .bf-arrow {
        background-image: url(../../../image/brainyfilter/sprite.png) !important;
    }
}

@media (max-width: $mobile) {
    /*category*/
    .products-filters.leftSidebar {
        max-width: unset;
        margin-right: 0;
    }
    #column-left {
        max-width: unset;
    }
    span.sale_badge i,
    span.new_arrival_badge.badge i {
        font-size: 1.2rem;
    }
    .badge {
        top: 0;
        left: 0;
        width: 57px;
        height: 25px;
    }
    .product-thumb a.add-to-wishlist {
        top: 20px;
        right: 18px;
    }
    .product-thumb .add-to-cart {
        right: 18px;
    }
    .product-thumb .image {
        padding: 0;
    }
}

@media (max-width: $mobile-sm) {
    /*category*/
    span.sale_badge i,
    span.new_arrival_badge.badge i {
        font-size: 1.1rem;
    }
    .badge {
        top: 0;
        left: 0;
        width: 57px;
        height: 25px;
    }
    .product-thumb a.add-to-wishlist {
        top: 10px;
        right: 10px;
    }
    .product-thumb .add-to-cart {
        right: 18px;
        bottom: 10px;
        width: 13px;
        height: 13px;
    }
}

@media (max-width: $mobile-xs) {
    /*category*/
    .product-thumb .add-to-cart {
        right: 15px;
    }
}

ul.pagination {
    display: none;
}


.load-more a{
    background: none;
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 0;
    box-sizing: border-box;
    color: #2d2d2d;
    cursor: pointer;
    /* font-family: futura-pt,sans-serif; */
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: normal;
    margin: 0;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.results-info {
    color: #000;
    margin-bottom: 20px;
    text-align: center;
}
.basel-spinner:after {
    content:"";
    display:inline-block;
    border: 2px solid #cccccc;
    border-top: 2px solid #333333;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 500ms linear infinite;
}
.category-pagination{
    border-top: none;
    padding: 50px 0 100px;
}

.basel-spinner.light:after {
    border: 2px solid #cccccc;
    border-top: 2px solid #ffffff;
}
.basel-spinner.ajax-call:after {
    position:fixed;
    top:50%;
    left:50%;
    margin:-18px auto auto -18px;
}
.basel-spinner.ajax-call {
    position:fixed;
    z-index:999;
    top:50%;
    left:50%;
    width:80px;
    height:80px;
    display:inline-block;
    background:transparent;
    border-radius:3px;
    margin:-40px auto auto -40px;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.caption-flex-box{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}