/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.medium-container {
  float: none;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}
#information-information .breadcrumb {
  display: none;
}
#information-information #content {
  text-align: justify;
  padding-bottom: 70px;
}
#information-information div,
#information-information span {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 0 10px !important;
}
#information-information h1,
#information-information h1 span{
  font-family: Mplus, sans-serif !important;
  text-align: center;
  font-size: 2.4rem !important;
  line-height: initial !important;
  letter-spacing: .025em !important;
  font-weight: 600 !important;
  color: #000 !important;
  margin: 0 !important;
  padding: 35px 0 30px !important;
}
#information-information h2,
#information-information h2 span{
  font-family: Mplus, sans-serif !important;
  font-size: 1.4rem !important;
  letter-spacing: 0.025em !important;
  line-height: initial !important;
  font-weight: 600 !important;
  color: #000 !important;
  margin: 0 !important;
  padding: 17px 0 15px !important;
  text-transform: uppercase !important;
}
#information-information p,
#information-information ul,
#information-information ol {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 0 10px !important;
}
#information-information li,
#information-information li span{
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 !important;
}
#information-information a,
#information-information a span{
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  color: #000 !important;
  text-decoration: underline !important;
  margin: 0 0 10px !important;
}
#information-information img {
  max-width: 100%;
}
