/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
#cart {
  .dropdown-menu {
    padding: 15px;
    background-color: #fff;
    border: none;
    min-width: 390px;
    right: -30px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
    @media (max-width: $tablet) {
      top: 0;
      right: 0;
      left: unset;
      -webkit-box-shadow: unset;
      -moz-box-shadow: unset;
      box-shadow: unset;
      min-width: auto;
      width: 100vw;
      position: fixed;
      height: 100vh;
      overflow-y: auto;
    }
    .close-mini-cart {
      justify-content: flex-end;
      margin-bottom:30px;
      a.close-mini-cart-btn {
        cursor: pointer;
        display: inline-block;
        .close-btn-icon  {
          display: inline-block;
          margin-right: 5px;
          width: 11px;
          height: 11px;
          svg {
            display: inline-block;
            width: 11px;
            height: 11px;
            .st0 {
              fill:none;
              stroke:$primary_color;
              stroke-width:3;
              stroke-linecap:round;
              stroke-miterlimit:10;
            }
          }
        }
        .close-btn-text {
          position: relative;
          display: inline-block;
          font-size: 1rem;
          line-height: initial;
          color: $primary_color;
          font-weight: 400;
          letter-spacing: 0.025em;
          top: -2px;
        }
      }
    }
    .all-products-w-button {
      .scrollable-products {
        overflow-y: auto;
        max-height: calc(100vh - 325px);
        &::-webkit-scrollbar {
          width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #000;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #000;
        }
        .text-center {
          font-size: 1.3rem;
          line-height: 2.4rem;
          font-weight: 400;
          letter-spacing: .025em;
        }
        li {
          .mini-cart-products {
            padding-bottom: 20px;
            .product-img {
              flex-basis: 20%;
              a {
                display: inline-block;
                .img-thumbnail {
                  border: none;
                  padding: 0;
                }
              }
            }
            .prd-item {
              flex-basis: 60%;
              .prd-name {
                font-size: 1.6rem;
                font-weight: 600;
                color: $primary_color;
                letter-spacing: .025em;
              }
              .prd-description, .prd-price {
                font-size: 1.2rem;
                font-weight: 400;
                color: $primary_color;
                letter-spacing: .025em;
                margin: 0;
              }
            }
            .product-remove {
              flex-basis: 20%;
              .remove-cart-item {
                border: none;
                background-color: transparent;
                .delete-icon {
                  display: block;
                  width: 9px;
                  height: 9px;
                  svg {
                    display: block;
                    width: 9px;
                    height: 9px;
                    .st0 {
                      fill:none;
                      stroke:#8c8c8c;
                      stroke-width:3;
                      stroke-linecap:round;
                      stroke-miterlimit:10;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .mobile-bottom-cart {
        padding-top: 20px;
        @media (max-width: $tablet) {
          position: absolute;
          bottom: 60px;
          width: 100%;
          left: 0;
          padding: 0 15px;
        }
        .mini-cart-total {
          width: 100%;
          .col-total {
            font-size: 1.3rem;
            line-height: 2.4rem;
            font-weight: 600;
            letter-spacing: .022em;
            background-color: #e4e4e4;
            color: $primary_color;
            text-align: center;
            text-transform: uppercase;
            padding: 25px;
            margin-bottom: 15px;
            &:nth-child(2) {
              display: none;
            }
          }
        }
        .mini-cart-checkout {
          width: 100%;
          background-color: $primary_color;
          a {
            width: 100%;
            text-align: center;
            padding: 15px;
            font-size: 1.3rem;
            line-height: 2.4rem;
            font-weight: 600;
            letter-spacing: 0.075em;
            color: #fff;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}