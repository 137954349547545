/*========================================================*/
/*** SEARCH RESULTS ***/
/*========================================================*/
#result_block p.pull-right.block-text.text-left ,
#result_block p.block-text.text-left {
  display: none;
}
.search-container {
  display: block;
  width: 100%;
  padding: 80px 35px 100px 35px;
}
.search-container .breadcrumb {
  display: none;
}
.flex-row.search-checkboxes {
  margin-top: 20px;
}
.search-checkbox-item:first-child {
  margin-right: 20px;
}
.search-container input#button-search {
  margin: 35px 0;
}
@media (max-width: $tablet-sm) {
  /*search results*/
  .search-container {
    padding: 50px 15px 0;
  }
  #input-search {
    margin-bottom: 15px;
  }
  .flex-row.search-checkboxes {
    flex-direction: column;
  }
  #button-search {
    max-width: unset;
  }
}