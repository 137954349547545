/*========================================================*/
/*** MENU style_d ***/
/*========================================================*/
#mega_menu {
  .mobile-enabled {
    a, h3 {
      font-family: $primary_font!important;
    }
    &.container {
      padding-bottom: 40px;
    }
  }
  ul.megamenu {
    li {
      .sub-menu {
        .content {
          padding: 30px 0 0!important;
          .hover-menu {
            .menu {
              ul {
                li {
                  ul {
                    margin: 0;
                    padding: 0!important;
                    font-size: 1.3rem;
                    line-height: 3rem;
                    font-weight: 400;
                    letter-spacing: 0.025em;
                  }
                }
              }
            }
          }
        }
      }
      a {
        font-family: $primary_font;
        font-size: 1.7rem;
        line-height: 3.6rem;
        letter-spacing: 0.025em;
        font-weight: 400;
      }
    }
    &.slide {
      display: flex;
      align-items: center;
      justify-content: center;
      li.active {
        .sub-menu {
          .container {
            .content {
              border: none;
              padding: 50px 35px 40px 35px!important;
            }
          }
        }
      }
    }
  }
  .main_link {
    h3 {
      border-bottom: none;
      padding: 0;
      font-size: 1.6rem;
      line-height: 3.6rem;
      letter-spacing: 0.025em;
    }
  }
  #menu {
    margin-bottom: 0;
  }
  .menu {
    .subtitle {
      a {
        font-weight: 600!important;
      }
    }

  }
  .sub-menu-wrapper {
    .menu-banner {
      text-align: center;
      .subtitle {
        a {
          font-weight: 600!important;
        }
      }
      h3 {
        font-size: 1.6rem;
        line-height: 3.6rem;
        margin-bottom: 15px;
        letter-spacing: 0.025em;
      }
    }
    ul.list-unstyled{
      &.subcategory {
        li.subtitle2{
          &:hover {
            .newhover {
              a {
                font-weight: 600!important;
              }
            }
          }
        }
      }

    }
  }
  .container-sub-menu {
    width: 100%;
  }
  i.fa.fonticon {
    display: none;
  }
}

a.menutitle {
  position: relative;
  padding: 0;
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 1px;
    background-color: $primary_color;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: width .3s ease;
    -moz-transition: width .3s ease;
    -o-transition: width .3s ease;
    transition: width .3s ease;
  }
}

li.with-sub-menu.hover.active a.menutitle::after{
  width: 100%;
}
ul.list-unstyled.subcategory li.subtitle2 .newhover a {
  position: relative;
}
ul.list-unstyled.subcategory li.subtitle2 .newhover a::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 1px;
  background-color: $primary_color;
  position: absolute;
  bottom: 3px;
  left: 0;
  -webkit-transition: width .3s ease;
  -moz-transition: width .3s ease;
  -o-transition: width .3s ease;
  transition: width .3s ease;
}
ul.list-unstyled.subcategory li.subtitle2:hover .newhover a::after {
  width: 100%;
}

.sub-menu-banner {
  position: relative;
  transition: linear .3s;
  &:hover {
    opacity: 0.9;
    transition: linear .3s;
  }
  img {
    position: relative;
  }
  a.shop-now-btn {
    position: absolute;
    bottom: 10px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    text-decoration: underline;
    font-size: 1.4rem;
    line-height: 3rem;
    font-weight: 600;
    letter-spacing: 0.025em;
  }
}

.megamenu-style-dev.responsive {
  margin-bottom: 0;
}

.megamenu-style-dev {
  .navbar-default {
    background-color: transparent;
    border-color: transparent;
  }
  .megamenu-wrapper {
    background-color: transparent;
    ul.megamenu {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      li {

      }
    }
  }
}



.megamenu-wrapper ul.megamenu > li > a {
  font-size: 1.7rem;
  text-transform: unset;
  padding: 25px;
}
.megamenu-wrapper ul.megamenu > li > a .caret {
  display: none;
}
.megamenu-wrapper ul.megamenu > li:hover,
.megamenu-wrapper ul.megamenu > li > a:hover,
.megamenu-wrapper ul.megamenu > li.active > a,
.megamenu-wrapper ul.megamenu > li.home > a,
.megamenu-wrapper ul.megamenu > li:hover > a {
  background-color: transparent;
  color: $primary_color;
}
.megamenu-style-dev .container-megamenu.horizontal ul.megamenu > li > a:before {
  content: none;
}

/*submenu*/
.megamenu-wrapper ul.megamenu > li > .sub-menu {
  left: 0;
  padding-top: 35px;
}
.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu {
  background-color: #fff;
}
.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content {
  max-width: 1580px;
  padding: 0 20px;
  margin: 0 auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.megamenu-wrapper .megamenu .sub-menu .content > .row > div {
  width: 20%;
}
.megamenu-wrapper ul.megamenu .title-submenu:before {
  content: none;
}
.megamenu-wrapper ul.megamenu .title-submenu {
  margin-bottom: 0;
}
.megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
  padding-top: 15px;
}
.megamenu-wrapper  ul.megamenu li .sub-menu .content .static-menu a.main-menu {
  font-size: 1.4rem;
  padding: 0;
  width: auto;
  display: inline-block;
  position: relative;
}
ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
  padding-top: 20px;
}
.megamenu-wrapper ul.megamenu .title-submenu {
  display: inline-block;
  width: auto;
  padding-bottom: 0;
  margin-bottom: 10px;
}
.megamenu-wrapper .menu-banner .title-submenu {
  position: relative;
}
.megamenu-wrapper .categories li ul li a:hover,
.megamenu-wrapper .subcategory li ul li a:hover {
  margin-left: 0;
}
.megamenu-wrapper .subcategory .title-submenu:after,
.megamenu-wrapper .categories li ul li a:after,
.megamenu-wrapper .subcategory li ul li a:after,
.megamenu-wrapper .manufacturer li a:after {
  content: '';
  width: 0;
  height: 2px;
  background-color: $primary_color;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: width .3s ease;
  -moz-transition: width .3s ease;
  -o-transition: width .3s ease;
  transition: width .3s ease;
}
.megamenu-wrapper .subcategory .title-submenu:hover:after,
.megamenu-wrapper .categories li ul li a:hover:after,
.megamenu-wrapper .subcategory li ul li a:hover:after,
.megamenu-wrapper .manufacturer li a:hover:after {
  width: 100%;
  margin-left: 0;
  -webkit-transition: width .3s ease;
  -moz-transition: width .3s ease;
  -o-transition: width .3s ease;
  transition: width .3s ease;
}
.megamenu-wrapper .menu-banner-link {
  position: relative;
}
.megamenu-wrapper .menu-banner-link .text {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}
.megamenu-wrapper ul.megamenu .menu-banner .title-submenu {
  text-align: center;
  width: 100%;
}
.megamenu-wrapper .menu-banner a {
  display: inline-block;
}
.megamenu-wrapper .menu-banner-link .text {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
}

/*submenu*/
.megamenu-wrapper ul.megamenu > li > .sub-menu {
  left: 0;
  padding-top: 35px;
}
.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu {
  background-color: #fff;
}
.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content {
  max-width: 1580px;
  padding: 0 30px;
  margin: 0 auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.megamenu-wrapper {
  ul.megamenu {
    .sub-menu {
      .content {
        .static-menu {
          .menu {
            ul {
              margin-top: 10px;
              li {
                padding-top: 20px;
              }
            }
          }
          a.main-menu {
            font-size: 1.4rem;
            padding: 0;
          }
        }
      }
    }
    .title-submenu{
      margin-bottom: 0;
      &:before {
        content: none;
      }
    }
  }
  .categories, .subcategory {
    li {
      ul {
        li {
          a {
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
          }
        }
      }
    }

  }
  .menu-banner-link {
    position: relative;
    .text {
      font-size: 1.4rem;
      font-weight: 600;
      color: #fff;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 20px;
      padding-bottom: 5px;
      border-bottom: 2px solid #fff;
    }
  }
  .menu-banner {
    .title-submenu {
      text-align: center;
    }
    a {
      display: inline-block;
    }
  }
  .manufacturer {
    li {
      display: block;
      a {
        color: $primary_color;
        border: 0;
        display: inline-block;
        width: auto;
        position: relative;
        padding: 0;
      }
    }
  }
}

/*discount bar*/
.sub-menu .discount-bar {
  margin-top: 40px;
}

@media (max-width: $laptop) {
  /*menu*/
  .megamenu-style-dev .megamenu-wrapper {
    padding-top: 10px;
  }
  .megamenu-wrapper ul.megamenu > li > a {
    font-size: 1.5rem;
    padding: 15px 22px;
  }
  ul.megamenu .title-submenu {
    font-size: 1.5rem;
  }
  .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu a.main-menu {
    font-size: 1.3rem;
  }
}

@media (max-width: $tablet) {
  /*menu*/
  body.menu-is-open {
    overflow: hidden;
  }
  .body-cover {
    position: fixed;
    z-index: 99;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  body.menu-is-open .body-cover {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }


  .megamenu-style-dev {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 15px;
    .megamenu-pattern {
      height: calc(100vh - 51px);
      overflow-y: auto;
      background-color: $white;
    }
    .navbar-default {
      .navbar-toggle {
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: 30px;
        padding: 0;
        height: 34px;
        display: inline-block;
        padding-top: 5px;
        margin-top: 15px;
        span {
          position: relative;
          display: block;
          width: 100%;
          height: 2px;
          margin-bottom: 5px;
          background-color: #000;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        &.active {
          span {
            &:nth-child(2) {
              opacity: 0;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
            }
            &:nth-child(1) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
              top: 10px;
            }
            &:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
              bottom: 4px;
            }
          }
        }
      }
    }
    .megamenu-wrapper {
      display: block;
      padding: 0;
      position: fixed;
      top: 51px;
      left: -100%;
      z-index: 999;
      height: calc(100vh - 51px);
      max-height: unset;
      opacity: 0;
      visibility: hidden;
      background-color: #fff;
      -webkit-transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
      -moz-transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
      -o-transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
      transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      &.so-megamenu-active {
        left: 0;
        opacity: 1;
        visibility: visible;
        overflow-y: hidden;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
      .megamenu {
        flex-direction: column;
        li {
          a {
            color: #000;
          }
        }
      }
    }
  }

  /*submenu*/
  .megamenu-wrapper .megamenu .sub-menu .content > .row > div {
    width: 100%;
  }
  .megamenu-wrapper ul.megamenu > li > .sub-menu {
    padding-top: 0;
  }
  .megamenu-wrapper.so-megamenu-active ul.megamenu .title-submenu,
  .megamenu-wrapper.so-vertical-active ul.megamenu .title-submenu {
    display: inline-block;
    max-width: unset;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .megamenu-wrapper ul.megamenu .subcategory {
    margin-top: 10px;
  }

  .megamenu-wrapper {
    .sub-menu {
      padding-bottom: 20px;
      .discount-bar {
        display: none;
      }
      .content {
        .static-menu {
          .menu {
            ul {
              li {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
  }



  /*menu*/
  .mobile-account-items {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    max-height: 51px;
    height: 100%;
    a {
      display: inline-block;
      float: left;
      width: 50%;
      background-color: #eaeaea;
      padding: 15px 15px 10px;
      text-align: center;
      color: #000;
      &:nth-child(2) {
        border-left: 1px solid rgba(0, 0, 0, 0.7);
        border-right: 1px solid rgba(0, 0, 0, 0.7);
      }
      .text {
        vertical-align: top;
      }
    }
  }


  .language-switcher {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    float: left;
    width: 33.333333%;
    align-items: center;
    justify-content: center;
    background: #eaeaea;
    height: 100%;
    .dropdown-menu {
      padding: 5px 0 10px 15px;
    }
    span, button .fa {
      color: #000;
    }
  }

  .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu .menu ul {
    margin-top: 0;
  }
  .container-megamenu.horizontal ul.megamenu > li,
  .container-megamenu.vertical ul.megamenu > li {
    border-bottom: 1px solid #f0f0f0;
  }

  /* menu mobile */
  .megamenu-wrapper ul.megamenu > li > a .caret {
    display: inline-block;
    content: ''!important;
    width: 8px;
    height: 5px;
    background-image: url('../../../icons/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px 5px;
    border: 0;
    float: right;
    padding: 10px;
  }
  .horizontal ul.megamenu > li.with-sub-menu > a:after {
    display: none!important;
  }
  li.with-sub-menu.active-show.click .caret {
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}

@media (max-width: $mobile) {
  /*menu*/
  .megamenu-style-dev .megamenu-wrapper {
    width: 85%;
  }
  .more-category-wrapper.flex-row {
    flex-direction: column;
  }
  .more-category-wrapper .category-item {
    width: 100%;
    margin: 0 auto 15px;
  }
}

/*Mega menu*/
@media (min-width: 1001px) and (max-width: 1025px) {
  #mega_menu #menu .sub-menu .container {
    max-width: 970px;
    width: 100%;
  }
}
