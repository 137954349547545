.checkout-container {
    #main-header,
    #search {
        display: none;
    }
    #d_logo {
        padding: clamp(15px, 2.6042vw, 50px) $side_padding_header;
        a {
            display: inline-block;
            margin: 0 auto;
        }
    }
    .checkout-header {
        margin: 0 0 clamp(25px, 5.2083vw, 100px);
        padding: 0 $side_padding_header;
    }
    .header-2 {
        margin: 0;
    }
    a {
        color: $primary_color;
        text-decoration: underline;
    }
    .guest-login-register {
        display: flex;
        .btn-group {
            flex-basis: 33.333333%;
            @media (max-width: $mobile) {
                &:nth-child(1),
                &:nth-child(2) {
                    flex-basis: 50%;
                    margin: 0 0 5px;
                }
                &:nth-child(3) {
                    flex-basis: 100%;
                }
            }
        }
    }
    .checkout-left {
        border-right: 1px solid $border_color;
        padding-right: $side_padding_checkout;
        @media (max-width: $tablet) {
            border-right: unset;
            padding-right: 0;
            padding-left: 0;
        }
    }
    .checkout-right {
        padding-left: $side_padding_checkout;
        @media (max-width: $tablet) {
            padding-right: 0;
            padding-left: 0;
        }
    }
    #d_quickcheckout {
        padding: 0 $side_padding_checkout clamp(25px, 5.2083vw, 100px);
        max-width: 1800px;
        width: 100%;
    }
    // Bootstrap fixes
    .form-horizontal {
        .radio,
        .checkbox,
        .radio-inline,
        .checkbox-inline {
            padding-top: 0;
        }
        @media (max-width: $tablet-sm) {
            .form-group {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
    .well {
        box-shadow: none;
    }
    #qc_confirm_order {
        padding: 20px 15px;
        &:disabled {
            color: $border_color;
            background-color: $white;
            border-color: $border_color;
            transition: all linear 0.3s;
        }
        @media (max-width: $mobile) {
            padding: 15px;
        }
    }
    .text-danger {
        margin: 0 !important;
    }
    button {
        &.decrease,
        &.increase {
            display: none;
        }
        &.delete {
            background-color: unset;
            background-image: none;
            background-repeat: no-repeat;
            border: none;
            border-color: unset;
        }
    }

    .panel {
        border-radius: 0;
        border: none;
        background: none;
        box-shadow: none;
        @media (max-width: $tablet-sm) {
            margin-bottom: 0;
        }
        .panel-body {
            padding: 15px 0;
            @media (max-width: $tablet-sm) {
                padding: 10px;
            }
        }
        .panel-heading {
            background-color: unset;
            border-radius: 0;
            border: none;
            padding: 10px 0;
            @media (max-width: $tablet-sm) {
                padding: 10px 10px 0 10px;
            }
        }
    }

    #payment_address_heading_heading > i {
        display: none;
    }
    .btn {
        box-shadow: none !important;
        text-shadow: none !important;
    }

    // Guest / Login / Register
    #login_view .guest-login-register {
        display: flex;
        flex-wrap: wrap;
    }

    #cart_view {
        .qc-name a {
            text-decoration: none;
        }
        .table,
        .table td {
            border: none;
            @media (max-width: $mobile) {
                font-size: 1.2rem;
            }
        }
        .table thead {
            border-bottom: 1px solid $border_color;
        }
        .qc-coupon {
            margin: 0 0 30px;
            .col-sm-12 {
                padding: 0;
            }
            #coupon,
            #confirm_coupon {
                height: 40px;
            }
            #coupon {
                width: 100%;
                min-height: 35px;
                padding: 5px 15px;
            }
            #confirm_coupon {
                display: block;
                max-width: 135px;
                width: 100%;
                padding: 5px 20px;
                height: 40px;
            }
        }
        .qc-totals {
            label {
                text-align: left !important;
                font-weight: 600;
                padding: 5px 0;
            }
            .row {
                margin: 0;
                font-size: $header_3_fs;
                text-align: right;
                border-bottom: 1px solid $border_color;
                padding: 10px 0;
                &:last-child {
                    border: 0;
                    margin-top: 0;
                }
            }
        }
        .qc-product-qantity {
            max-width: 50px;
            min-height: 35px;
            padding: 5px;
        }
        .qc-quantity {
            width: 20%;
        }
        .qc-checkout-product table.table tbody td {
            vertical-align: middle;
        }
    }

    #payment_address_form,
    #shipping_address_form {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }
    #payment_address_form > div,
    #shipping_address_form > div {
        flex-basis: calc(50% + 15px);
    }
    #payment_address_newsletter_input,
    #payment_address_email_input,
    #payment_address_email_confirm_input,
    #payment_address_company_input,
    #payment_address_address_1_input,
    #payment_address_city_input,
    #payment_address_postcode_input,
    #payment_address_customer_group_id_input,
    #payment_address_custom_field\.account\.1_input,
    #payment_address_shipping_address_input,
    #payment_address_agree_input,
    #payment_address_heading_heading {
        flex-basis: calc(100% + 30px) !important;
    }
    #payment_address_customer_group_id_input .col-xs-12:first-child {
        display: none;
    }
    #confirm_form {
        margin-bottom: clamp(30px, 3.125vw, 60px);
    }
    #confirm_comment {
        min-height: 140px;
        resize: vertical;
    }
    #payment_address_newsletter_input,
    #payment_address_shipping_address_input,
    #payment_address_agree_input,
    #payment_address_heading_heading {
        label {
            text-align: left;
        }
    }
    #payment_address_heading_heading {
        margin-bottom: 30px;
        @media (max-width: $tablet-sm) {
            margin-bottom: 10px;
        }
    }
    #login_model {
        top: 50%;
        height: fit-content;
        transform: translateY(-50%);
    }

    @media (max-width: $tablet-sm) {
        #payment_address_form > div,
        #shipping_address_form > div {
            flex-basis: calc(100% + 30px) !important;
        }
        #payment_address_form > div > div,
        #shipping_address_form > div > div,
        #confirm_comment_input > div,
        #confirm_agree_input > div {
            padding: 0;
        }
        #payment_view {
            padding: 10px;
        }
    }
}
