/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container {
  padding: 0 30px;
}
.product-row .left-col {
  max-width: 1255px;
  width: 100%;
  padding-right: 25px;
}
.product-row .right-col {
  max-width: 540px;
  width: 100%;
  padding: 0 25px;
}

/*Product images*/
.product-thumbnails {
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-thumbnails .thumb {
  flex-basis: 50%;
  margin-bottom: 30px;
}
.single-image.product-thumbnails .thumb {
  flex-basis: 100%;
}
.single-image.product-thumbnails .thumb img {
  margin: 0 auto;
}
.product-thumbnails .thumb:nth-child(odd) {
  padding-right: 15px;
}
.product-thumbnails .thumb:nth-child(even) {
  padding-left: 15px;
}
.product-row .slider-arrows {
  display: none;
}

/*Product details*/
.product-header {
  justify-content: space-between;
  align-items: center;
}
.product-header h1 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: .035rem;
  margin: 10px 0;
  padding-right: 10px;
}
.wishlist-btn {
  background-color: transparent;
  border: none;
}
.wishlist-fill-icon {
  width: 100%;
  display: flex;
  margin-top: 24px;
  svg {
    display: block;
    width: 21px;
    margin-left: 15px;
    .st0 {
      fill: #bcbcbc;
      stroke: #000;
      stroke-width: 1px;
    }
  }
}
.is-favorite {
  .wishlist-fill-icon {
    svg {
      .st0 {
        fill: #EC1D25;
        stroke: #EC1D25;
      }
    }
  }
}

.price-list {
  margin: 30px auto;
}
.price-list li {
  display: inline-block;
  padding-right: 15px;
}
.product-price {
  font-size: 1.8rem;
  font-weight: 600;
}
.product-price.special {
  color: #ff8b00;
}
.product-price.old {
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: line-through;
}

.product-available-colors {
  margin-bottom: 20px;
}
.product-available-colors .color {
  display: block;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 600;
}
.product-available-colors .flex-row {
  flex-wrap: wrap;
}
.product-available-colors .flex-row li {
  max-width: 60px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.product-available-colors .flex-row li.selected {
  border: 1px solid #000;
}

.product-info .table {
  margin-bottom: 0;
}
.product-info .table>thead>tr>td,
.product-info .table>tbody>tr>td {
  padding-left: 0;
}

.select-options .control-label {
  font-size: 1.2rem;
  font-weight: 600;
}
.quantity-input-field {
  display: none;
}
.quantity-input-field.show {
  display: block;
}
.quantity-input-field .number-input {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.quantity-input-field .number-input .control-label {
  flex-basis: 10%;
  border: none;
  margin-bottom: 0;
  padding: 12px 15px;
  border-left: none;
  border-right: none;
}
.quantity-input-field .quantity {
  flex-basis: 90%;
}
.quantity-input-field  .form-control {
  border-left: none;
}
#product .btn-default {
  width: 100%;
}
.select-options .form-control {
  border-radius: 0;
  height: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  border-color: #000;
  -webkit-appearance: none;
}
.select-options select.form-control {
  cursor: pointer;
  background-image: url('../../../icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: 98% 50%;
}
.select-options .form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.select-options .quantity {
  position: relative;
}
.select-options .quantity input[type="text"] {
  padding-right: 30px;
  text-align: right;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}
.select-options .quantity-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 25px;
}
.select-options .quantity-nav.flex-column {
  align-items: center;
  justify-content: space-evenly;
}
.select-options .quantity-nav .quantity-button  {
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

/*product info*/
.product-info.panel-group .panel {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-top: 1px solid #d6d6d6;
}
.product-info.panel-group .panel:last-child {
  border-bottom: 1px solid #d6d6d6;
}
.panel-group .panel+.panel {
  margin: 0;
}
.product-info .panel-heading {
  color: #000;
  padding: 25px 5px;
  background-color: transparent;
}
.product-info .panel-title > a {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
}
.product-info .panel-default>.panel-heading+.panel-collapse>.panel-body {
  border: none;
  padding-top: 0;
}
.product-info .panel-body {
  padding-left: 5px;
}
.product-info .panel-body * {
  font-size: 1.3rem;
  letter-spacing: .035rem;
}

/*related products*/
.related-products {
  position: relative;
  border-top: 1px solid #e4e4e4;
  margin-top: 120px;
  padding-top: 100px;
  .slick-slider {
    padding-bottom: 55px;
  }
}
.related-products h3 {
  margin: 30px auto;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}
.related-products .slick-slide {
  height: auto;
}
.related-products.carousel-container .carousel-arrows {
  bottom: 15px;
}
/*combine-with*/
.related-products.combine-with {
  border-top: none;
  margin-top: 0;
  margin-bottom: 120px;
}

/*add to cart message*/
@keyframes note {
  0% {margin-top:25px;opacity:0;}
  10% {margin-top:0;opacity:1;}
  90% {margin-top:0;opacity:1;}
  100% {margin-top:25px;opacity:0;}
}
.popup-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 50px;
  margin-bottom: 0;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  z-index: 9999;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  animation: note 6500ms forwards;
}
.popup-alert a,
.popup-alert a:hover {
  color: #000;
  font-weight: 500;
}
.product-msg {
  margin-bottom: 30px;
  display: inline-block;
  margin-right: 10px;
  img {
    display: inline-block;
    margin-right: 10px;
  }
}
.apothema img {
  width: 22px;
}
.apothema {
  color: #068300;
}
.prosfora img {
  width: 30px;
}

.free-delivery-msg img {
  width: 24px;
  position: relative;
  top: -2px;
}

@media (max-width: $laptop-sm) {
  /*checkout page*/
  .checkout-container.container {
    width: 100%;
  }
  .navbar {
    padding: 15px;
  }
  .checkout-logo span.logo-icon svg,
  .checkout-logo span.logo-icon {
    width: 220px;
    height: auto;
  }
}

@media (max-width: $tablet-lg) {
  /*product*/
  .carousel-container {
    margin: 50px auto;
  }
  .related-products {
    padding-top: 50px;
  }
  .related-products.combine-with {
    margin-bottom: 50px;
  }
}

@media (max-width: $tablet-sm) {
  /*product*/
  .product-container {
    padding: 0 15px;
  }
  .product-row.flex-row {
    flex-direction: column;
  }
  .product-row .left-col {
    padding-right: 0;
  }
  .product-row .right-col {
    padding: 0;
    max-width: unset;
  }
  .product-thumbnails .thumb {
    margin-bottom: 0;
  }
  .product-thumbnails .thumb:nth-child(even),
  .product-thumbnails .thumb:nth-child(odd) {
    padding: 0;
  }
  .product-thumbnails .slick-slide img {
    margin: 0 auto;
  }
  .product-row .slider-arrows {
    display: block;
    top: 0;
    height: 100%;
    -webkit-transform: unset;
    -moz-transform: unset;
    -o-transform: unset;
    transform: unset;
    position: absolute;
    width: 100%;
  }
  .product-row .slider-arrows .arrow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70px;
    position: absolute;
  }
  .product-row .slider-arrows .arrow.arrow-prev {
    left: 0;
  }
  .product-row .slider-arrows .arrow.arrow-next {
    right: 0;
  }
  .product-row .slider-arrows .arrow svg {
    fill: #000;
    display: inline-block;
    width: 22px;
    height: 41px;
  }
  .products-block .product-thumb {
    margin-bottom: 15px;
  }
  .flex-row.category-pagination {
    padding: 15px 0;
  }
  .popup-alert {
    padding: 15px;
    width: 90%;
  }

}

.price-plakaki-list li{
  display: block;
}

.related-products .carousel-arrows {
  display: none;
}
.page_product {
  input[type=text] {
    border: 1px solid #000000;
  }
  .select-options {
    margin-bottom: 24px;
    .metatropi {
      margin-top: 10px;
    }
  }
  .related-products {
    .caption {
      margin-top: 15px;
      padding: 0 10px;
      h4 {
        font-size: $header_3_fs;
      }
    }
    h3 {
      margin: 0 0 60px;
      font-size: 2.6rem;
    }
  }
}