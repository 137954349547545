input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
textarea,
select,
label.control-label,
input[type='text'].form-control,
input[type='password'].form-control,
input[type='tel'].form-control,
input[type='email'].form-control,
textarea.form-control,
select.form-control,
.radio label,
.checkbox label,
label.control-label {
    font-size: $input_fs;
    font-weight: 400;
    color: $primary_color;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
textarea,
select,
input[type='text'].form-control,
input[type='password'].form-control,
input[type='tel'].form-control,
input[type='email'].form-control,
textarea.form-control,
select.form-control {
    border: 1px solid $border_color;
    padding: 15px;
    height: unset;
    line-height: 1.5;
    background-color: transparent;
    box-shadow: none;
    border-radius: $border_radius_input;
    &::placeholder {
        color: $placeholder_color;
        font-weight: 300;
    }
    &:focus {
        border: 1px solid $border_color_focus;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border: 1px solid $primary_color;
        -webkit-text-fill-color: $primary_color;
        box-shadow: 0 0 0px 1000px $border_color inset;
    }
}
// Bootstrap default
.form-group.required .control-label:after {
    content: ' *';
    color: red;
}
