/*========================================================*/
/* Layout*/
/*========================================================*/
.flex-row {
    display: flex;
}
.flex-column {
    display: flex;
    flex-direction: column;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.overflow-hidden {
    overflow: hidden;
}

.float-right {
    float: right;
}

.side-padding {
    @include sidePadding($side_padding);
}

// reset helping classes
.m0 {
    margin: 0;
}

.mt0 {
    margin-top: 0;
}

.mb0 {
    margin-bottom: 0;
}

.p0 {
    padding: 0;
}

// Megamenu helping class
.mobile-account-items {
    display: none;
}

/*========================================================*/
/*** Default Elements ***/
/*========================================================*/
.btn-default {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 10px 15px;
    font-size: $btn_fs;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: $btn_font_color;
    background-color: $btn_background_color;
    border: 1px solid $btn_background_color;
    border-radius: $border_radius_button;
    background-image: none;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.8;
    transition: ease 0.3s;
    min-height: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:hover {
        color: $btn_background_color;
        background-color: transparent;
        border-color: $btn_background_color;
    }
    &.reverse {
        color: $btn_font_color;
        background-color: $btn_background_color;
        &:hover {
            background-color: transparent;
            color: $btn_background_color;
        }
    }
    &.active {
        color: $white;
        background-color: $secondary_color;
        border-color: $secondary_color;
        &:hover {
            background-color: transparent;
            color: $btn_background_color;
        }
    }
    &.medium-button {
        max-width: 250px;
    }
}

// Bootstrap defaults
.alert {
    margin: 15px;
}
.alert-success {
    color: $white;
    background-color: $black;
    border-color: $black;
    font-size: $text_fs;
    border-radius: 0;
    text-align: center;
}
.alert {
    margin: 15px;
}
.btn-primary,
.btn-info {
    color: $white;
    background-color: $black;
    border-color: $black;
    &:hover {
        color: $white;
        background-color: $black;
        border-color: $black;
    }
}

.slider-handle {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    justify-content: space-between;
    margin: auto;
    pointer-events: none;
    max-height: vw(584px);

    &>div {
        &>span {
            display: block;
            width: max(15px, vw(28px));
        }
    }

    &.recommended {
        top: vw(100px);
        width: vw(580px);

        @media screen and (max-width: $tablet) {
            width: vw-rs($tablet, 686px)
        }

        @media screen and (max-width: $tablet-sm) {
            width: vw-rs(375px, 307px)
        }
    }

    &.sub-recommended,
    &.best-sellers,
    &.special-offers {
        top: 0;
        width: 100%;

        @media screen and (max-width: $tablet-sm) {
            top: -35px;
            width: 65%;
            filter: invert(1);
        }
    }

    &.prev-collection {
        top: 0;

        &--cover-flow {
            @media screen and (max-width: $tablet-sm) {
                width: 65%;
                filter: invert(1);
            }
        }
    }

    &.single-post {
        top: 0;
        width: 100%;

        &--cover-flow {
            @media screen and (max-width: $tablet-sm) {
                width: 65%;
                filter: invert(1);
            }
        }
    }

    &.shop-the-look {
        top: vw(100px);
        width: vw(640px);

        @media screen and (max-width: $tablet) {
            width: vw-rs($tablet, 686px)
        }

        @media screen and (max-width: $tablet-sm) {
            width: vw-rs(375px, 307px)
        }
    }

    &.social {
        top: 0;
        width: 100%;

        .homepage__social__item-prev {
            right: vw(65px);
        }

        .homepage__social__item-next {
            left: vw(65px);
        }
    }

    &>* {
        cursor: pointer;
        pointer-events: all;

        @media screen and (min-width: ($tablet + 1)) {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &.store-handles {
        top: 0;
        width: 100%;

        width: 106%;
        left: -3%;

        @media screen and (max-width: $laptop) {
            width: 107%;
            left: -3.5%;
        }

        @media screen and (max-width: $tablet-lg) {
            width: 110%;
            left: -5%;
        }

        @media screen and (max-width: $tablet-sm) {
            width: 110%;
            left: -5%;

            &>* {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        @media screen and (max-width: $mobile) {
            width: 120%;
            left: -10%;
        }
    }
}
